import React from "react";

export default function StaticErrorMessage({ oneDataNotFoundFlag }) {
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-sm lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <img
              className="mx-auto mb-4"
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/404/404-computer.svg"
              alt="404 Not Found"
            />
            <p className="mb-10 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
              Sorry, no product found!
            </p>
            <p className="mb-4 text-gray-500 dark:text-gray-400">
              {oneDataNotFoundFlag
                ? "Reload the page"
                : "Please check the spelling or try searching for something else:"}
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
