import axiosApiInstance from "../../libs/axios";
import { useEffect, useState } from "react";
import PageLoading from "../../components/PageLoading";

export default function OrdersList({
  ordersData,
  setOrdersData,
  windowSize,
  searchValue,
  setSearchValue,
  currentPage,
  setCurrentPage,
}) {
  const [isSearchValueHasChanged, setIsSearchValueHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onSearchButtonClick = () => {
    setIsLoading(true);
    const page = isSearchValueHasChanged ? 1 : currentPage;
    page === 1 && setCurrentPage(1);
    const url =
      searchValue !== ""
        ? "/orders?page=" +
          page +
          "&view=" +
          windowSize +
          "&search=" +
          searchValue
        : "/orders?page=" + page + "&view=" + windowSize;
    axiosApiInstance
      .get(url)
      .then((data) => {
        setOrdersData(data?.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };

  const onClearSearchButtonClick = () => {
    setIsLoading(true);
    setSearchValue("");
    const url = "/orders?page=1&view=" + windowSize;
    axiosApiInstance
      .get(url)
      .then((data) => {
        setOrdersData(data?.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setIsSearchValueHasChanged(true);
  }, [searchValue]);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg m-12 max-lg:m-4">
      <div className="max-w-3xl mx-auto p-6 flex gap-4 content-center items-center">
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Search
        </label>
        <div className="relative w-[36rem]">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search..."
            required
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyUp={(e) => {
              if (e.code === "Enter") e.target.nextElementSibling.click();
            }}
          />
          <button
            type="submit"
            className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={onSearchButtonClick}
          >
            Search
          </button>
        </div>
        <button
          type="button"
          className={`text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ${searchValue === "" ? "bg-gray-500 cursor-not-allowed" : "bg-blue-700 hover:bg-blue-800"}`}
          onClick={onClearSearchButtonClick}
          disabled={searchValue === ""}
        >
          Clear
        </button>
      </div>
      <table className="w-full text-sm rtl:text-right text-gray-500 dark:text-gray-400 text-center">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 max-lg:px-4 py-3 text-base">
              ID
            </th>
            <th scope="col" className="px-6 py-3 text-base max-lg:hidden">
              Date
            </th>
            <th scope="col" className="px-6 max-lg:px-4 py-3 text-base">
              Customer Email
            </th>
            <th scope="col" className="px-6 max-lg:px-4 py-3 text-base">
              Submitted
            </th>
            <th scope="col" className="px-6 max-lg:px-4 py-3"></th>
          </tr>
        </thead>
        <tbody className="text-base">
          {isLoading ? (
            <tr>
              <td></td>
              <td></td>
              <td>
                <PageLoading />
              </td>
              <td></td>
              <td></td>
            </tr>
          ) : ordersData?.orders?.length === 0 ? (
            <tr>
              <td></td>
              <td></td>
              <td>
                <p className="mt-8 mb-8 text-xl">No data found.</p>
              </td>
              <td></td>
              <td></td>
            </tr>
          ) : (
            ordersData?.orders?.map?.((order, i) => {
              const created_at = new Date(order.createdAt);
              const date = created_at.getDate();
              const month = created_at.toDateString().split(" ")[1];
              const year = created_at.getFullYear();
              const formattedDate = [date, month, year].join(" ");
              return (
                <tr
                  key={i}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 max-lg:px-4 py-4">{order.name}</td>
                  <td className="px-6 py-4 max-lg:hidden">{formattedDate}</td>
                  <td className="px-6 max-lg:px-4 py-4">{order.email}</td>
                  <td className="px-6 max-lg:px-4 py-4">
                    {order.completed}/{order.count}
                  </td>
                  <td className="px-6 max-lg:px-4 py-4">
                    <a href={"/orders/" + order.id}>
                      <button
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                      >
                        Edit
                      </button>
                    </a>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}
