export default function Header() {
  const pathname = window.location.pathname;
  const currentPage = pathname.startsWith("/products")
    ? "products"
    : pathname.startsWith("/manual-order")
      ? "manual-order"
      : "orders";
  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900 border-b">
      <div className="flex flex-wrap items-center justify-between mx-auto  pl-10 pr-10">
        <div className="flex items-center gap-24">
          <div className="self-center text-2xl font-medium whitespace-nowrap dark:text-white">
            Album Manager
          </div>
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col  md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <a
                  href="/orders/"
                  className={`block py-4 text-xl font-normal ${currentPage === "orders" ? "text-white bg-blue-700 rounded border-b-blue-700 border-b-2 md:bg-transparent md:text-blue-700 dark:text-white md:dark:text-blue-500" : "text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700  dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"}`}
                >
                  Orders
                </a>
              </li>
              <li>
                <a
                  href="/products/"
                  className={`block py-4  text-xl font-normal ${currentPage === "products" ? "text-white bg-blue-700 rounded border-b-blue-700 border-b-2 md:bg-transparent md:text-blue-700  dark:text-white md:dark:text-blue-500" : "text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700  dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"}`}
                >
                  Products
                </a>
              </li>
              <li>
                <a
                  href="/manual-order/"
                  className={`block py-4   text-xl font-normal ${currentPage === "manual-order" ? "text-white bg-blue-700 rounded border-b-blue-700 border-b-2 md:bg-transparent md:text-blue-700  dark:text-white md:dark:text-blue-500" : "text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700  dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"}`}
                >
                  Manual Order
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
