import OrdersList from "./OrdersList";
import { useEffect, useState } from "react";
import axiosApiInstance from "../../libs/axios";
import { Pagination } from "flowbite-react";
import PageLoading from "../../components/PageLoading";
import { toast } from "react-toastify";

export default function OrderPage({ setIsSessionExpired }) {
  const [ordersData, setOrdersData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const windowSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const [searchValue, setSearchValue] = useState("");

  const onPageChange = (page) => {
    setIsLoading(true);
    setCurrentPage(page);
    const url =
      searchValue !== ""
        ? "/orders?page=" +
          page +
          "&view=" +
          windowSize +
          "&search=" +
          searchValue
        : "/orders?page=" + page + "&view=" + windowSize;
    axiosApiInstance
      .get(url)
      .then((data) => {
        setOrdersData(data?.data);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };
  const totalPages =
    ordersData?.orders?.length > 0
      ? ordersData.totalCount % windowSize === 0
        ? parseInt(ordersData.totalCount / windowSize)
        : parseInt(ordersData.totalCount / windowSize) + 1
      : 0;

  useEffect(() => {
    axiosApiInstance
      .get("/orders?page=1&view=" + windowSize)
      .then((data) => {
        setOrdersData(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          setIsSessionExpired(true);
        } else {
          toast.error("Something went wrong. Please refresh the page once.", {
            position: "top-right",
          });
          setIsLoading(false);
        }
      });
  }, []);
  return (
    <>
      {isLoading ? (
        <PageLoading />
      ) : (
        <>
          <OrdersList
            ordersData={ordersData}
            setOrdersData={setOrdersData}
            windowSize={windowSize}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <div className="flex overflow-x-auto sm:justify-center mt-6 mb-6">
            {ordersData?.orders?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
                showIcons
              />
            )}
          </div>
        </>
      )}
    </>
  );
}
