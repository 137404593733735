import VariantRowData from "./VariantRowData";

function AccordionCom({ variantData, onAnyVariantDataChange }) {
  return (
    <>
      <tr className="text-xs mx-50 bg-gray-50 dark:bg-gray-900">
        <th scope="col" colSpan="1" className="px-4 py-3 text-base"></th>
        <th
          scope="col"
          id="column1"
          colSpan="1"
          className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 text-base"
        >
          Variant Name
        </th>
        <th
          scope="col"
          id="column2"
          colSpan="1"
          className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 text-base"
        >
          Customization Type
        </th>
        <th
          scope="col"
          id="column3"
          colSpan="1"
          className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 text-base"
        >
          Number of sides for audio
        </th>
        <th
          scope="col"
          id="column4"
          className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 text-base"
        >
          Per Side Limit (Minutes)
        </th>
      </tr>

      {variantData.map((variant, i) => (
        <VariantRowData
          variantDetails={variant}
          key={variant.id}
          onAnyInputChanged={(newVariant) => {
            const newVariantData = [...variantData];
            newVariantData[i] = newVariant;
            onAnyVariantDataChange(newVariant);
          }}
        />
      ))}
    </>
  );
}

export default AccordionCom;
