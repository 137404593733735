import RowHtmlFlowBitCode from "./RowHtmlFlowBitCode";
import AccordionCom from "./AccordionCom";

const RowWithAccordion = ({
  isOpen,
  toggleAccordion,
  productData,
  onVariantDataChanged,
}) => {
  return (
    <>
      <tr
        className="border-b dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer transition"
        id="table-column-header-0"
        data-accordion-target="#table-column-body-0"
        aria-expanded="false"
        aria-controls="table-column-body-0"
        key={productData.id}
        onClick={toggleAccordion}
      >
        <RowHtmlFlowBitCode
          productData={productData}
          isOpen={isOpen ? true : false}
        />
      </tr>

      {isOpen && (
        <AccordionCom
          onAnyVariantDataChange={(newVariants) => {
            return onVariantDataChanged(newVariants);
          }}
          variantData={productData.variants}
        />
      )}
    </>
  );
};

export default RowWithAccordion;
