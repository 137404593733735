import { QueryClient } from '@tanstack/react-query';

const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      useErrorBoundary: true,
      staleTime: Infinity,
    },
    mutations: {
      useErrorBoundary: true,
    },
  },
});

export default reactQueryClient;
