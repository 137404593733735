import React, { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import PageLoading from "./components/PageLoading";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClientProvider } from "@tanstack/react-query";
import reactQueryClient from "./libs/react-query-client.js";
import ErrorBoundaryComp from "./components/ErrorBoundaryComp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {generateNewToken, getToken, updateAuthHeader} from "./libs/axios";

// eslint-disable-next-line no-undef
if (process.env.REACT_APP_ENV !== "local") {
  const token = new URL(window.location.href).searchParams.get("token");
  updateAuthHeader(token);
}

const main = () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <StrictMode>
      <ErrorBoundary FallbackComponent={ErrorBoundaryComp}>
        <Suspense fallback={<PageLoading />}>
          <QueryClientProvider client={reactQueryClient}>
            <BrowserRouter>
              <App />
              <ToastContainer />
            </BrowserRouter>
          </QueryClientProvider>
        </Suspense>
      </ErrorBoundary>
    </StrictMode>,
  );
};

const token = getToken();

if (!token) {
  generateNewToken().then(() => main());
} else {
  main();
}

