import { useContext, useState } from "react";
import { SaveButtonFlagContext } from "./DataContext";

function VariantRowData({ variantDetails, onAnyInputChanged }) {
  const [, setSaveButtonFlag] = useContext(SaveButtonFlagContext);
  const [error, setError] = useState("");

  const onValueChange = (key, value) => {
    variantDetails[key] = value;
    const newValue = value;
    // const newValue = value.replace(/\D+/g, "");

    if (key == "customize_status") {
      if (
        key === "customize_status" &&
        (value == "audio" ||
          value == "image_audio" ||
          value == "audio_label" ||
          value == "image_audio_labels")
      ) {
        variantDetails["record_sides"] =
          variantDetails?.record_sides !== "none" &&
          variantDetails.record_sides !== undefined && 
          variantDetails.record_sides !== ""
            ? variantDetails.record_sides
            : "1side";
        variantDetails["audio_timings"] =
          variantDetails.audio_timings > 0 &&
          variantDetails.record_sides !== undefined
            ? variantDetails.audio_timings
            : "1";
        onAnyInputChanged({
          ...variantDetails,
          [key]: value,
          isValueChanged: true,
        });
        setSaveButtonFlag(true);
        setError("");
      }
      if (
        key === "customize_status" &&
        (value === "none" || value === "image")
      ) {
        variantDetails["record_sides"] = "none";
        variantDetails["audio_timings"] = " ";
        onAnyInputChanged({
          ...variantDetails,
          [key]: value,
          isValueChanged: true,
        });
        setSaveButtonFlag(true);
        setError("");
      }
    } else if (
      key == "audio_timings" &&
      (/^[1-9]\d*$/.test(newValue) || newValue === "" || newValue.includes("e"))
    ) {
      if (
        newValue === "" ||
        newValue === "0" ||
        isNaN(newValue) ||
        newValue <= 0
      ) {
        onAnyInputChanged({
          ...variantDetails,
          [key]: newValue,
          isValueChanged: true,
        });
        setSaveButtonFlag(false);
        setError("positive number excluding 0");
      } else {
        onAnyInputChanged({
          ...variantDetails,
          [key]: newValue,
          isValueChanged: true,
        });
        setSaveButtonFlag(true);
        setError("");
      }
    } else {
      onAnyInputChanged({
        ...variantDetails,
        [key]: value,
        isValueChanged: true,
      });
      setSaveButtonFlag(true);
    }
  };

  return (
    <>
      <tr>
        <td scope="col" colSpan="1" className="px-4 py-3 text-base"></td>
        <td
          scope="col"
          id="column1"
          colSpan="1"
          className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 text-base"
        >
          {variantDetails.title}
        </td>
        <td
          scope="col"
          id="column2"
          colSpan="1"
          className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 text-base"
        >
          <select
            id="customize_status"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer"
            value={variantDetails.customize_status || "none"}
            onChange={(event) =>
              onValueChange("customize_status", event.target.value)
            }
          >
            <option value="none">None</option>
            <option value="audio">Audio</option>
            <option value="image_audio">Audio + Jacket</option>
            <option value="audio_label">Audio + Label</option>
            <option value="image_audio_labels">Audio + Labels + Jacket </option>
            <option value="image">Jacket</option>
          </select>
        </td>
        <td
          scope="col"
          id="column3"
          colSpan="1"
          className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 text-base"
        >
          <select
            id="record_sides"
            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              variantDetails.customize_status == "audio" ||
              variantDetails.customize_status == "image_audio" ||
              variantDetails.customize_status == "audio_label" ||
              variantDetails.customize_status == "image_audio_labels"
                ? "cursor-pointer"
                : ""
            }`}
            disabled={
              variantDetails.customize_status == "audio" ||
              variantDetails.customize_status == "image_audio" ||
              variantDetails.customize_status == "audio_label" ||
              variantDetails.customize_status == "image_audio_labels"
                ? false
                : true
            }
            onChange={(event) =>
              onValueChange("record_sides", event.target.value)
            }
            value={variantDetails.record_sides}
          >
            <option
              value="none"
              disabled={
                variantDetails.customize_status == "audio" ||
                variantDetails.customize_status == "image_audio" ||
                variantDetails.customize_status == "audio_label" ||
                variantDetails.customize_status == "image_audio_labels"
                  ? true
                  : false
              }
            >
              None
            </option>
            <option value="1side">1 side</option>
            <option value="2side">2 side</option>
          </select>
        </td>
        <td
          scope="col"
          id="column4"
          className="relative min-w-[10rem] w-auto max-w-[10rem] px-4 py-4 text-base"
        >
          <input
            type="number"
            id="audio_timings"
            aria-describedby="helper-text-explanation"
            className={` bg-gray-50 border border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              variantDetails.customize_status == "audio" ||
              variantDetails.customize_status == "image_audio" ||
              variantDetails.customize_status == "audio_label" ||
              variantDetails.customize_status == "image_audio_labels"
                ? "text-gray-900"
                : "text-gray-500"
            }`}
            // value={
            //   variantDetails.audio_timings ? variantDetails.audio_timings : ""
            // }
            min={0}
            disabled={
              variantDetails.customize_status == "audio" ||
              variantDetails.customize_status == "image_audio" ||
              variantDetails.customize_status == "audio_label" ||
              variantDetails.customize_status == "image_audio_labels"
                ? false
                : true
            }
            onChange={(event) => {
              return onValueChange("audio_timings", event.target.value);
            }}
            required
            value={variantDetails.audio_timings || ""}
          />
          {error && (
            <div className="absolute text-xs top-13 left-5 text-red-600 ">
              {error}
            </div>
          )}
        </td>
      </tr>
    </>
  );
}

export default VariantRowData;
