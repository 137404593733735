import { Modal } from "flowbite-react";

export default function OrderSuccessModal({
  isOrderSuccessModalOpen,
  setIsOrderSuccessModalOpen,
  createdOrderId,
}) {
  const email = document.querySelector("#email")?.value;
  return (
    <Modal
      dismissible
      show={isOrderSuccessModalOpen}
      onClose={() => setIsOrderSuccessModalOpen(false)}
    >
      <Modal.Header>Order Created Successfully!</Modal.Header>
      <Modal.Body>
        <p>{`Please note down the ID: ${createdOrderId}.`}</p>
        <br />
        <p>
          {`The link for the order is: `}
          <a
            target="_blank"
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
            href={`${process.env.REACT_APP_SHOP_URL}/pages/create-freestylemlveda?order_id=${createdOrderId}&email=${email}`}
            rel="noreferrer"
          >{`${process.env.REACT_APP_SHOP_URL}/pages/create-freestylemlveda?order_id=${createdOrderId}&email=${email}`}</a>
        </p>
      </Modal.Body>
    </Modal>
  );
}
