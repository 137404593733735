import { useParams } from "react-router-dom";
import OrderDetailsData from "./OrderDetailsData";
import { useEffect, useState } from "react";
import axiosApiInstance from "../../libs/axios";
import PageLoading from "../../components/PageLoading";

export default function OrderDetailsPage() {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axiosApiInstance
      .get("/orders?id=" + orderId)
      .then((data) => {
        setOrderData(data.data);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      {isLoading ? <PageLoading /> : <OrderDetailsData orderData={orderData} />}
    </>
  );
}
