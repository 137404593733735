import { createContext } from "react";

const AllProductDataContext = createContext(1);
const DBDataContext = createContext(1);
const UpdatedProductDataContext = createContext(1);

const SaveButtonFlagContext = createContext(1);
const StoreFontTokenGet = createContext(1);


export {
  AllProductDataContext,
  DBDataContext,
  UpdatedProductDataContext,
  SaveButtonFlagContext,
  StoreFontTokenGet,
};
