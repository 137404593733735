import React, { useState } from "react";
import RowWithAccordion from "./RowWithAccordion";

const TableRowCom = ({ allProductsData, onVariantDataChanged }) => {
  const [currOpenAccordion, setCurrOpenAccordion] = useState(null);

  return (
    <>
      {allProductsData.map((product) => {
        return (
          <RowWithAccordion
            onVariantDataChanged={(newVariantData) => onVariantDataChanged(newVariantData, product.id)}
            toggleAccordion={() =>
              setCurrOpenAccordion(
                currOpenAccordion === product.id ? null : product.id
              )
            }
            // Self close
            onAccordionClose={() => setCurrOpenAccordion(null)}
            // Open the accordion
            isOpen={currOpenAccordion === product.id}
            key={product.id}
            productData={product}
          />
        );
      })}
    </>
  );
};

export default TableRowCom;
