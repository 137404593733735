import axios from "axios";

let token;

const axiosApiInstance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_HOST,
});

const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const getCookie = (cname) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const generateNewToken = async () => {
  await axiosApiInstance.get("/getToken").then((res) => {
    res.data && setCookie("token", res.data, 1);
    if (res.data) {
      axiosApiInstance.interceptors.request.use((config) => {
        console.log('Using token ', res.data);
        // eslint-disable-next-line no-param-reassign
        config.headers.authorization = `Bearer ${res.data}`;
        return config;
      });
    }
  });
};

// eslint-disable-next-line no-undef
if (process.env.REACT_APP_ENV === "local") {
  token = getCookie("token");
}
if (!token || token === 'null') {
  token = undefined;
}

if (token) {
  axiosApiInstance.interceptors.request.use((config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.authorization = `Bearer ${token}`;
    return config;
  });
}
export const updateAuthHeader = (newToken) => {
  if (!newToken) {
    const tokenInSession = window.sessionStorage.getItem("token");
    if (tokenInSession) {
      token = tokenInSession === 'null' ? undefined : tokenInSession;
      axiosApiInstance.interceptors.request.use((config) => {
        console.log('Using token ', token);
        // eslint-disable-next-line no-param-reassign
        config.headers.authorization = `Bearer ${token}`;
        return config;
      });
      return;
    }
  }

  token = newToken && newToken !== 'null' ? newToken : undefined;
  if (token) {
    axiosApiInstance.interceptors.request.use((config) => {
      console.log('Using token ', token);
      // eslint-disable-next-line no-param-reassign
      config.headers.authorization = `Bearer ${token}`;
      return config;
    });
    window.sessionStorage.setItem("token", newToken);
  }
};

export const getToken = () => token;

export default axiosApiInstance;
