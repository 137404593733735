function RowHtmlFlowBitCode({ productData, isOpen }) {
  function customizProuductCount() {
    const x = productData.variants.reduce((variantsCount, variantItem) => {
      return variantItem.customize_status == "image" ||
        variantItem.customize_status == "audio_label" ||
        variantItem.customize_status == "image_audio_labels" ||
        variantItem.customize_status == "audio" ||
        variantItem.customize_status == "image_audio"
        ? ++variantsCount
        : variantsCount;
    }, 0);

    return x;
  }
  return (
    <>
      <td className="p-3 w-4">
        {!isOpen ? (
          <svg
            data-accordion-icon=""
            className="w-6 h-6 shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 12.707l-3.293-3.293a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L10 12.707z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg
            data-accordion-icon=""
            className="w-6 h-6 shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </td>
      <th
        scope="row"
        className="min-w-[10rem] w-auto overflow-x-hidden px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white flex items-center"
      >
        <img
          src="https://flowbite.s3.amazonaws.com/blocks/application-ui/products/imac-front-image.png"
          alt="iMac Front Image"
          className="h-8 w-auto mr-3"
        />
        {productData.title}
      </th>
      <td className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {/* {customizProuductCount()}/{productData.variants.length} custom products */}
        {productData.variants.length} total variants
      </td>
      <td className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {customizProuductCount() > 0 ? "Yes" : "No"}
      </td>
      <td className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"></td>
    </>
  );
}

export default RowHtmlFlowBitCode;
