/* eslint-disable no-unreachable */
import axiosApiInstance from "../../libs/axios";
import { useContext, useEffect, useState } from "react";
import TableTitle from "./TableTitle";
import TableSearch from "./TableSearch";
import TableRowCom from "./TableRowCom";
import { Pagination } from "flowbite-react";
import {
  UpdatedProductDataContext,
  SaveButtonFlagContext,
  DBDataContext,
  StoreFontTokenGet,
} from "./DataContext";
import axios from "axios";
import { toast } from "react-toastify";
import StaticErrorMessage from "./StaticErrorMessage";

function Table({ oneDataNotFoundFlag }) {
  const dbDataContext = useContext(DBDataContext);
  const [updatedAllProductsData, setUpdatedAllProductsData] = useContext(
    UpdatedProductDataContext
  );
  const APP_STOREFRONTTOKEN = useContext(StoreFontTokenGet);

  const [isPostAPICallFlag, setIsPostAPICallFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [saveButtonFlag, setSaveButtonFlag] = useState(false);
  const [searchProductsData, setSearchProductsData] = useState([]);
  const [isSearchBtnEnable, IsSearchBtnEnable] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [isSecondApiCallSucceed, setIsSecondApiCallSucceed] = useState(false);

  const [currentPageData, setCurrentPageData] = useState([]);
  const [searchTextState, setSearchTextState] = useState("");
  const [searchProductsFlag, setSearchProductsFlag] = useState(true);
  const [customizeProductsCount, setCustomizeProductsCount] = useState(0);
  let windowSize = 5;

  function onPageChange(page) {
    setIsLoading(true);
    setCurrentPage(page);
    let startIndex = (page - 1) * windowSize;
    let endIndex = startIndex + windowSize;
    if (searchProductsData.length >= 0 && searchTextState != "") {
      setCurrentPageData(searchProductsData.slice(startIndex, endIndex));
      setTotalPages(Math.ceil(searchProductsData.length / windowSize));
      setIsLoading(false);
    } else {
      setCurrentPageData(updatedAllProductsData.slice(startIndex, endIndex));
      setTotalPages(Math.ceil(updatedAllProductsData.length / windowSize));
      setIsLoading(false);
    }
  }
  const handleSearch = async (searchText) => {
    IsSearchBtnEnable(false);
    setIsLoading(true);
    setSearchTextState(searchText);
    if (searchText == "") {
      setCurrentPageData([]);
      setSearchProductsData([]);
      setCurrentPage(1);
      setIsLoading(false);
      setSearchProductsFlag(true);
      toast.success("All Products Data Shown!", {
        position: "top-right",
      });
    } else {
      try {
        setCurrentPage(1);
        setCurrentPageData([]);
        const response = await axios.post(
          `${process.env.REACT_APP_SHOP_URL}/api/2024-01/graphql.json`,
          {
            query: `query searchProducts($query: String!) {
            products(first: 50, query: $query) {
             edges {
               node {
                 id
                 title
                 variants(first: 100) {
                   edges {
                     node {
                       id
                       sku
                       title
                     }
                   }
                 }
                 createdAt
                 updatedAt
               }
             }
          }
         }
         `,
            variables: {
              query: searchText,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": APP_STOREFRONTTOKEN,
            },
          }
        );

        console.log("search on table ", APP_STOREFRONTTOKEN);
        const formattedProducts = response?.data?.data?.products?.edges?.map(
          (edge) => {
            return {
              id: edge?.node?.id?.split("/").pop(),
              title: edge?.node?.title,
              variants: edge?.node?.variants?.edges?.map((variantEdge) => ({
                id: variantEdge?.node?.id?.split("/").pop(),
                title: variantEdge?.node?.title,
                sku: variantEdge?.node?.sku,
              })),
            };
          }
        );
        if (formattedProducts.length > 0) {
          setSearchProductsData(formattedProducts);
          setSearchProductsFlag(true);
          toast.success("Search Products Data Shown!", {
            position: "top-right",
          });
        } else {
          setSearchProductsData([]);
          setSearchProductsFlag(false);
          toast.error("Search Products Not Found!", {
            position: "top-right",
          });
        }

        setIsSecondApiCallSucceed(true);
        setCurrentPage(1);
        setIsLoading(false);
      } catch (error) {
        // console.error("Error fetching data from DBProductsData: ", error);
        setIsSecondApiCallSucceed(false);
        setIsLoading(false);
        setSearchProductsData([]);
        setSearchProductsFlag(false);
        toast.error("Search Products Not Found!", {
          position: "top-right",
        });
      }
    }
    IsSearchBtnEnable(true);
  };
  // updateAllProductsData function for combine query searchData and updateProductData
  const updateAllProductsData = () => {
    setIsLoading(true);
    if (searchProductsData.length > 0) {
      const updatedProducts = searchProductsData.map((searchProduct) => {
        // updatedAllProductsData use this because SHOPIFYALLPRODUCTSDATA & MLVDBPRODUCTSDATA combine in updatedAllProductsData
        const updatedCurrProduct = updatedAllProductsData?.find?.(
          (updatedProduct) => updatedProduct?.id === searchProduct?.id
        );
        if (updatedCurrProduct) {
          return {
            ...searchProduct,
            ...updatedCurrProduct,
            variants: updatedCurrProduct.variants,
          };
        } else {
          return searchProduct;
        }
      });
      setSearchProductsData(updatedProducts);
    } else {
      setSearchProductsData([]);
    }
    setIsLoading(false);
  };

  if (updatedAllProductsData.length > 0 && isSecondApiCallSucceed) {
    updateAllProductsData();
    setIsSecondApiCallSucceed(false);
    setIsLoading(false);
  }

  /////// customize changes submit button
  const handleCustomChangeSubmit = async () => {
    toast("Customize Products Data Saving...");
    IsSearchBtnEnable(false);
    try {
      setIsPostAPICallFlag(true);
      const dataToPost = [...updatedAllProductsData]
        .filter((product) =>
          product?.variants.some((variant) => variant.isValueChanged)
        )
        .map((product) => {
          const variantsStoredInDb = [];

          const variantsInDb = dbDataContext.find(
            (item) => item.id === product?.id
          )?.variants;

          if (variantsInDb) {
            for (let variantInDb of variantsInDb) {
              if (
                !product?.variants.find((v) => {
                  return v.id === variantInDb.id;
                })?.isValueChanged
              ) {
                variantsStoredInDb.push(variantInDb);
              }
            }
          }
          let returnData = {
            ...product,
            variants: product?.variants
              .filter((variant) => variant.isValueChanged)
              // eslint-disable-next-line no-unused-vars
              .map(({ isValueChanged, ...variant }) => variant)
              .concat(...variantsStoredInDb),
          };
          return returnData;
        });

      const response = await axiosApiInstance
        .post("/products", dataToPost)
        .then((response) => {
          setSaveButtonFlag(false);
          setIsPostAPICallFlag(false);
          toast.success("Customize Products Data Saved!", {
            position: "top-right",
          });

          let customizedCount = updatedAllProductsData.filter((product) => {
            let flag = false;
            for (let i = 0; i < product.variants.length; i++) {
              if (product.variants[i]?.customize_status === undefined) continue;
              if (
                product.variants[i]?.customize_status.indexOf("audio") !== -1 ||
                product.variants[i]?.customize_status.indexOf("image") !== -1
              ) {
                flag = true;
                break;
              }
            }
            if (flag) {
              return product;
            }
          }).length;
          setCustomizeProductsCount(customizedCount);

          return response;
        });
      IsSearchBtnEnable(true);
      return response;
    } catch (error) {
      // console.error("Error fetching data from post call: ", error);
      toast.error("Please try again to save data!", {
        position: "top-right",
      });
      setIsPostAPICallFlag(false);
      IsSearchBtnEnable(true);
    }
  };
  useEffect(() => {
    setCustomizeProductsCount(dbDataContext.length);
    setCurrentPageData([]);
    setIsLoading(true);
    onPageChange(currentPage);
  }, [updatedAllProductsData, searchProductsData, currentPage]);
  return (
    <>
      <section className="py-3 sm:py-5">
        <div className="mx-auto max-w-screen-2xl px-4 lg:px-12">
          <TableTitle
            allProductCount={updatedAllProductsData.length}
            customProductCount={customizeProductsCount}
          />
          {/* Selfcomment note tableserch inputbox with input function  */}
          <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
            <div className="flex flex-row md:flex-rowitems-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4 border-b dark:border-gray-700">
              <TableSearch
                onSearch={handleSearch}
                isSearchBtnEnable={isSearchBtnEnable}
              />
              <div className=" flex gap-4 content-center items-center">
                <button
                  type="button"
                  className={`min-w-28 py-5 flex items-center justify-center text-white font-medium rounded-lg text-xl px-3 focus:outline-none ${
                    !saveButtonFlag
                      ? "bg-gray-500 focus:ring-4 dark:bg-gray-600 focus:ring-gray-800"
                      : "cursor-pointer bg-blue-700 hover:bg-blue-800 focus:ring-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-blue-800"
                  }`}
                  disabled={!saveButtonFlag || isPostAPICallFlag}
                  onClick={() => handleCustomChangeSubmit()}
                >
                  {!isPostAPICallFlag ? (
                    "Save"
                  ) : (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 me-3 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                      Saving...
                    </>
                  )}
                </button>
              </div>
            </div>
            {isLoading || currentPageData.length == 0 ? (
              !searchProductsFlag || oneDataNotFoundFlag ? (
                <StaticErrorMessage oneDataNotFoundFlag={oneDataNotFoundFlag} />
              ) : (
                <div
                  role="status"
                  className="w-full h-full flex items-center justify-center"
                >
                  <div>
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )
            ) : (
              <>
                <div className="overflow-x-auto">
                  {/* Selfcomment table individual rows for every product item */}
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    {/* Selfcomment table Headings for product details header  */}
                    <thead className="text-xs uppercase bg-gray-100 dark:bg-gray-700">
                      <tr>
                        <th scope="col" id="column2" className="px-4 py-3">
                          <span className="sr-only">Expand/Collapse Row</span>
                        </th>
                        <th
                          scope="col"
                          id="column3"
                          className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 text-lg "
                        >
                          Product
                        </th>
                        <th
                          scope="col"
                          id="column4"
                          className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 text-lg "
                        >
                          Customizable Variants
                        </th>
                        <th
                          scope="col"
                          id="column5"
                          className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 text-lg "
                        >
                          Customizable
                        </th>
                        <th
                          scope="col"
                          id="column6"
                          className="min-w-[10rem] w-auto max-w-[10rem] px-4 py-3 text-lg "
                        ></th>
                      </tr>
                    </thead>

                    <tbody>
                      <SaveButtonFlagContext.Provider
                        value={[saveButtonFlag, setSaveButtonFlag]}
                      >
                        <TableRowCom
                          onVariantDataChanged={(changedVariant, productId) => {
                            const newProductsData = [...updatedAllProductsData];
                            const productToUpdate = newProductsData.find(
                              (product) => product.id === productId
                            );
                            const variantIndex =
                              productToUpdate.variants.findIndex(
                                (variant) => variant.id === changedVariant.id
                              );
                            productToUpdate.variants[variantIndex] =
                              changedVariant;
                            // const newProductsData = [...updatedAllProductsData];

                            // const i = newProductsData.findIndex(
                            //   (oldProductData) =>
                            //     oldProductData.id === changedProductData.id
                            // );
                            // if (i > -1) {
                            //   newProductsData[i] = changedProductData;
                            // }

                            setUpdatedAllProductsData(newProductsData);
                          }}
                          allProductsData={currentPageData}
                        />
                      </SaveButtonFlagContext.Provider>
                    </tbody>
                  </table>
                </div>
                <div
                  className="flex flex-col md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 px-4 pt-3 pb-4"
                  aria-label="Table navigation"
                >
                  <div className="flex overflow-x-auto sm:justify-center mt-6 mb-6">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={onPageChange}
                      showIcons
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Table;
