import { useState } from "react";

function TableSearch({ onSearch, isSearchBtnEnable }) {
  const [searchText, setSearchText] = useState("");
  const handleSearch = () => {
    onSearch(searchText);
  };
  const handleClearSearch = () => {
    onSearch("");
  };

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch(searchText);
    }
    // else if (
    //   (event.key === "Backspace" || event.key === "Delete") &&
    //   searchText === ""
    // ) {
    //   onSearch(searchText);
    // }
  };
  return (
    <>
      <div className="max-w-3xl mx-auto p-6 flex gap-4 content-center items-center">
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Search
        </label>
        <div className="relative w-[36rem]">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full p-4 ps-10 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            // onKeyUp={(e) => {
            //   if (e.code === "Enter") e.target.nextElementSibling.click();

            // }}
            placeholder="Search product name..."
            required
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            disabled={!isSearchBtnEnable}
            onKeyUp={handleOnKeyDown}
          />
          <button
            type="submit"
            className={`text-white absolute end-2.5 bottom-2.5 ${
              !isSearchBtnEnable
                ? "bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                : "bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 cursor-pointer "
            }`}
            onClick={handleSearch}
            disabled={!isSearchBtnEnable}
          >
            Search
          </button>
        </div>
        <button
          type="button"
          className={`text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ${searchText === "" ? "bg-gray-500 cursor-not-allowed" : "bg-blue-700 hover:bg-blue-800"}`}
          onClick={() => {
            setSearchText("");
            handleClearSearch();
          }}
          // disabled={!isSearchBtnEnable}
          disabled={searchText === ""}
        >
          Clear
        </button>
      </div>
    </>
  );
}
export default TableSearch;
