import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import ProductPage from "./pages/products";
import OrderPage from "./pages/orders";
import OrderDetailsPage from "./pages/orders/OrderDetailsPage";
import ManualOrder from "./pages/orders/ManualOrder";
import Header from "./components/Header";
import { useState } from "react";
import SessionExpired from "./components/SessionExpired";

function App() {
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  return (
    <>
      {isSessionExpired ? (
        <SessionExpired />
      ) : (
        <>
          <Header />
          <Routes>
            <Route path="/products" element={<ProductPage />} />
            <Route
              path="/orders"
              element={<OrderPage setIsSessionExpired={setIsSessionExpired} />}
            />
            <Route path="/orders/:orderId" element={<OrderDetailsPage />} />
            <Route path="/manual-order" element={<ManualOrder />} />
            <Route path="*" element={<Navigate to="/orders" />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
