import { Button, Flowbite, Modal } from "flowbite-react";
import { Fragment, useEffect, useState } from "react";
import axiosApiInstance from "../../libs/axios";
import PageLoading from "../../components/PageLoading";

export default function BrowseProductsModal({
  isBrowseProductsModalOpen,
  setIsBrowseProductsModalOpen,
  selectedLineItems,
  setSelectedLineItems,
  selectedSkuList,
  setSelectedSkuList,
  selectedVariantsList,
  setSelectedVariantsList,
}) {
  const customTheme = {
    button: {
      color: {
        primary:
          "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800",
      },
    },
  };

  const [allProductsData, setAllProductsData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axiosApiInstance
      .get("/products/all")
      .then((data) => {
        if (data?.data) {
          const productsData = [];
          data.data.forEach((productJson) => {
            const customizableVariantsData = productJson.variants.filter(
              (variant) => variant.customize_status !== "none",
            );
            customizableVariantsData.length > 0 &&
              productsData.push({
                ...productJson,
                variants: customizableVariantsData,
              });
          });
          setAllProductsData(productsData);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const onProductCheckboxClick = (e) => {
    document
      .querySelectorAll(
        '[data-product-id="' +
          e.target.getAttribute("data-product-id") +
          '"].variant',
      )
      .forEach((variantInputElement) => {
        variantInputElement.checked = e.target.checked;
      });
  };

  const onVariantCheckboxClick = (e) => {
    if (
      document.querySelectorAll(
        '[data-product-id="' +
          e.target.getAttribute("data-product-id") +
          '"].variant',
      ).length ===
      document.querySelectorAll(
        '[data-product-id="' +
          e.target.getAttribute("data-product-id") +
          '"].variant:checked',
      ).length
    ) {
      document.querySelector(
        '[data-product-id="' +
          e.target.getAttribute("data-product-id") +
          '"].product',
      ).checked = e.target.checked;
    } else {
      document.querySelector(
        '[data-product-id="' +
          e.target.getAttribute("data-product-id") +
          '"].product',
      ).checked = false;
    }
  };

  const onAddClick = () => {
    selectedSkuList.length !== 0 &&
      document
        .querySelector("#zero-products-selected-validation")
        .classList.add("hidden");
    const lineItems = [];
    const skus = [];
    document.querySelectorAll(".variant:checked").forEach((variantInput) => {
      const data = {};
      data.product_id = variantInput.getAttribute("data-product-id");
      data.variant_id =
        variantInput.parentElement.parentElement.getAttribute(
          "data-variant-id",
        );
      data.sku =
        variantInput.parentElement.parentElement.getAttribute("data-sku");
      data.quantity =
        variantInput.parentElement.parentElement.querySelector(
          "#quantity-input",
        ).value;
      lineItems.push(data);
      skus.push(data.sku);
    });
    setSelectedVariantsList(lineItems.map((item) => item.variant_id));
    setSelectedSkuList(skus);
    setSelectedLineItems(lineItems);
    setIsBrowseProductsModalOpen(false);
  };

  const onBrowseAllProductsModalClose = () => {
    selectedSkuList.length !== 0 &&
      document
        .querySelector("#zero-products-selected-validation")
        .classList.add("hidden");
    setIsBrowseProductsModalOpen(false);
  };

  const quantityDecrement = (e) => {
    const mainElement =
      e.target.nodeName === "path"
        ? e.target.parentElement.parentElement.parentElement.parentElement
        : e.target.nodeName === "svg"
          ? e.target.parentElement.parentElement.parentElement
          : e.target.parentElement.parentElement;
    const quantityInput = mainElement.querySelector("#quantity-input");
    if (parseInt(quantityInput.value) === 1) return;
    quantityInput.value -= 1;
  };

  const quantityIncrement = (e) => {
    const mainElement =
      e.target.nodeName === "path"
        ? e.target.parentElement.parentElement.parentElement.parentElement
        : e.target.nodeName === "svg"
          ? e.target.parentElement.parentElement.parentElement
          : e.target.parentElement.parentElement;
    const quantityInput = mainElement.querySelector("#quantity-input");
    quantityInput.value = parseInt(quantityInput.value) + 1;
  };

  const onQuantityChange = (e) => {
    if (e.target.value === "") e.target.value = 1;
    if (e.target.value.match(/\D/g))
      e.target.value = parseInt(e.target.value.replaceAll(/\D/g, ""));
  };

  const onSearchClick = () => {
    setIsLoading(true);
    axiosApiInstance
      .get("/products/all?search=" + searchValue)
      .then((data) => {
        const productsData = [];
        data.data.forEach((productJson) => {
          const customizableVariantsData = productJson.variants.filter(
            (variant) => variant.customize_status !== "none",
          );
          customizableVariantsData.length > 0 &&
            productsData.push({
              ...productJson,
              variants: customizableVariantsData,
            });
        });
        setAllProductsData(productsData);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };

  const onClearSearchButtonClick = () => {
    setIsLoading(true);
    setSearchValue("");
    axiosApiInstance
      .get("/products/all")
      .then((data) => {
        const productsData = [];
        data.data.forEach((productJson) => {
          const customizableVariantsData = productJson.variants.filter(
            (variant) => variant.customize_status !== "none",
          );
          customizableVariantsData.length > 0 &&
            productsData.push({
              ...productJson,
              variants: customizableVariantsData,
            });
        });
        setAllProductsData(productsData);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      dismissible
      show={isBrowseProductsModalOpen}
      onClose={onBrowseAllProductsModalClose}
      size="2xl"
    >
      <Modal.Header>Add Products</Modal.Header>
      <Modal.Body>
        <div className="border-b">
          <form
            className="max-w-md mx-auto mb-4 flex gap-4 content-center items-center"
            onSubmit={(e) => e.preventDefault()}
          >
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div className="relative w-[24rem]">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search-products"
                className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyUp={(e) => {
                  if (e.code === "Enter") e.target.nextElementSibling.click();
                }}
              />
              <button
                type="submit"
                className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={onSearchClick}
              >
                Search
              </button>
            </div>
            <button
              type="button"
              className={`text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ${searchValue === "" ? "bg-gray-500 cursor-not-allowed" : "bg-blue-700 hover:bg-blue-800"}`}
              onClick={onClearSearchButtonClick}
              disabled={searchValue === ""}
            >
              Clear
            </button>
          </form>
          {isLoading ? (
            <PageLoading />
          ) : (
            allProductsData.map?.((productJson, i) => {
              const isProductDefaultChecked =
                productJson.variants.filter(
                  (variant) => selectedVariantsList.indexOf(variant.id) !== -1,
                ).length === productJson.variants.length;
              return (
                <Fragment key={i}>
                  <div className="pt-3 pb-3 border-t">
                    <input
                      type="checkbox"
                      className="product w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      data-product-id={productJson.id}
                      onClick={onProductCheckboxClick}
                      defaultChecked={isProductDefaultChecked}
                    />
                    <div className="ml-2 inline">{productJson.title}</div>
                  </div>
                  {productJson.variants.map((variant, i) => {
                    return (
                      <Fragment key={i}>
                        <div
                          className="flex justify-between pt-3 pb-3 border-t gap-4"
                          data-variant-id={variant.id}
                          data-sku={variant.sku}
                        >
                          <div className="ml-8">
                            <input
                              type="checkbox"
                              className="variant w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              data-product-id={productJson.id}
                              onClick={onVariantCheckboxClick}
                              defaultChecked={
                                selectedVariantsList.indexOf(variant.id) !== -1
                              }
                            />
                            <div className="ml-2 inline">{variant.title}</div>
                            <div className="ml-2 inline">
                              (SKU: {variant.sku})
                            </div>
                          </div>
                          <div className="relative flex items-center mr-8">
                            <button
                              type="button"
                              className="flex-shrink-0 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                              onClick={quantityDecrement}
                            >
                              <svg
                                className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 18 2"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M1 1h16"
                                />
                              </svg>
                            </button>
                            <input
                              type="number"
                              min={1}
                              defaultValue={
                                selectedLineItems.length > 0 &&
                                selectedVariantsList.indexOf(variant.id) !== -1
                                  ? selectedLineItems[
                                      selectedVariantsList.indexOf(variant.id)
                                    ].quantity
                                  : 1
                              }
                              id="quantity-input"
                              className="flex-shrink-0 text-gray-900 dark:text-white border-0 bg-transparent text-sm font-normal focus:outline-none focus:ring-0 max-w-12 text-center"
                              onChange={onQuantityChange}
                            />
                            <button
                              type="button"
                              className="flex-shrink-0 bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 inline-flex items-center justify-center border border-gray-300 rounded-md h-5 w-5 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                              onClick={quantityIncrement}
                            >
                              <svg
                                className="w-2.5 h-2.5 text-gray-900 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 18 18"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 1v16M1 9h16"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Flowbite theme={{ theme: customTheme }}>
          <Button color="primary" onClick={onAddClick}>
            Add
          </Button>
        </Flowbite>
        <Button color="light" onClick={onBrowseAllProductsModalClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
