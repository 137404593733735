import CoverImages from "./CoverImages";
import CustomLabels from "./CustomLabels";
import SongsList from "./SongsList";
import { useEffect, useState } from "react";
import axiosApiInstance from "../../libs/axios";
import PageLoading from "../../components/PageLoading";
import { toast } from "react-toastify";
import { Button, Flowbite, Modal } from "flowbite-react";

export default function LineItemDetails({
  isLoading,
  setIsLoading,
  currentIndex,
  setCurrentIndex,
  setPreviousIndex,
  setNextIndex,
  lineItemsCount,
  albumId,
  order_id,
}) {
  const lineItemStatuses = [
    "Creating",
    "In Production",
    "Fulfilled",
    "Cancelled",
  ];
  const [sideA, setSideA] = useState([]);
  const [sideB, setSideB] = useState([]);
  const customTheme = {
    button: {
      color: {
        primary:
          "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800",
      },
    },
  };

  const onPreviousButtonClick = () => {
    setIsLoading(true);
    const newIndex = currentIndex - 1;
    setCurrentIndex(newIndex);
    setPreviousIndex(newIndex - 1);
    setNextIndex(newIndex + 1);
  };
  const onNextButtonClick = () => {
    setIsLoading(true);
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex);
    setPreviousIndex(newIndex - 1);
    setNextIndex(newIndex + 1);
  };

  const [albumData, setAlbumData] = useState({});

  const [
    isOpenAlbumStatusUpdateConfirmationModal,
    setIsOpenAlbumStatusUpdateConfirmationModal,
  ] = useState(false);
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
  const [updatedAlbumStatusValue, setUpdatedAlbumStatusValue] = useState(
    albumData?.albumStatus
  );

  let showCoverImages = false;
  let showSongsList = false;
  let showCustomLabels = false;
  let recordSides = 2;
  let audioTimings = 0;
  let sku = "";
  if (albumData?.productDetails?.variants?.length > 0) {
    showCoverImages =
      albumData.productDetails.variants
        .filter((variant) => variant.id === albumData.variantId)[0]
        .customize_status.indexOf("image") !== -1;
    showSongsList =
      albumData.productDetails.variants
        .filter((variant) => variant.id === albumData.variantId)[0]
        .customize_status.indexOf("audio") !== -1;
    showCustomLabels =
      albumData.productDetails.variants
        .filter((variant) => variant.id === albumData.variantId)[0]
        .customize_status.indexOf("label") !== -1;
    recordSides = parseInt(
      albumData.productDetails.variants.filter(
        (variant) => variant.id === albumData.variantId
      )[0].record_sides
    );
    audioTimings = parseInt(
      albumData.productDetails.variants.filter(
        (variant) => variant.id === albumData.variantId
      )[0].audio_timings
    );
    sku = albumData.productDetails.variants.filter(
      (variant) => variant.id === albumData.variantId
    )[0].sku;
  }

  const onAlbumStatusChange = () => {
    setIsSaveButtonLoading(true);
    axiosApiInstance
      .put("/orders", {
        id: order_id,
        albumId: albumId,
        sideA: sideA,
        sideB: sideB,
        albumStatus: updatedAlbumStatusValue,
      })
      .then((data) => {
        document.querySelector("#album-status-selectbox").value =
          data.data.albumStatus;
        toast.success("Album Status Updated Successfully!", {
          position: "top-right",
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
        });
      })
      .finally(() => {
        setIsSaveButtonLoading(false);
        setIsOpenAlbumStatusUpdateConfirmationModal(false);
      });
  };

  useEffect(() => {
    if (albumId !== undefined && albumId !== null) {
      axiosApiInstance
        .get("/orders?albumId=" + albumId)
        .then((data) => {
          setAlbumData(data.data);
          setUpdatedAlbumStatusValue(data.data.albumStatus);
          setIsLoading(false);
          setSideA(data.data.sideA);
          setSideB(data.data.sideB);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Something went wrong. Please refresh the page once.", {
            position: "top-right",
          });
        });
    }
  }, [albumId]);

  return (
    <>
      {isLoading ? (
        <PageLoading />
      ) : (
        <div className="m-24 block p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <div className="flex justify-between mt-2">
            <div className="grid grid-cols-4 gap-8">
              <div className="flex items-center gap-2 col-start-1 col-end-3">
                <span className="font-normal text-2xl">
                  {currentIndex + 1}.{" "}
                </span>
                <div className="font-normal text-2xl">
                  {albumData?.productDetails?.title} (SKU: {sku})
                </div>
              </div>
              <select
                id="album-status-selectbox"
                value={updatedAlbumStatusValue}
                onChange={(e) => {
                  setUpdatedAlbumStatusValue(e.target.value);
                  setIsOpenAlbumStatusUpdateConfirmationModal(true);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4"
              >
                {lineItemStatuses.map((status, i) => {
                  return (
                    <option
                      key={i}
                      value={status.replaceAll(" ", "").toLowerCase()}
                    >
                      {status}
                    </option>
                  );
                })}
              </select>
              {albumData?.zipFileURL && (
                <button
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  onClick={() => {
                    window.open(albumData.zipFileURL, "_blank");
                  }}
                >
                  Download ZIP
                </button>
              )}
            </div>
            <div className="flex gap-4">
              <button
                type="button"
                className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
                onClick={onPreviousButtonClick}
                style={{ visibility: currentIndex === 0 && "hidden" }}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 12h14M5 12l4-4m-4 4 4 4"
                  />
                </svg>
              </button>
              <button
                type="button"
                className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
                onClick={onNextButtonClick}
                style={{
                  visibility: currentIndex === lineItemsCount - 1 && "hidden",
                }}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 12H5m14 0-4 4m4-4-4-4"
                  />
                </svg>
              </button>
            </div>
          </div>
          <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
          {showCoverImages && (
            <CoverImages
              albumData={albumData}
              isAlbumEditable={updatedAlbumStatusValue !== "inproduction"}
            />
          )}
          {showCustomLabels && (
            <CustomLabels
              albumData={albumData}
              isAlbumEditable={updatedAlbumStatusValue !== "inproduction"}
            />
          )}
          {showSongsList && (
            <SongsList
              isAlbumEditable={updatedAlbumStatusValue !== "inproduction"}
              albumData={albumData}
              setAlbumData={setAlbumData}
              sideA={sideA}
              sideB={sideB}
              recordSides={recordSides}
              audioTimings={audioTimings}
            />
          )}
        </div>
      )}
      <Modal
        dismissible
        show={isOpenAlbumStatusUpdateConfirmationModal}
        onClose={() => {
          setUpdatedAlbumStatusValue(albumData?.albumStatus);
          setIsOpenAlbumStatusUpdateConfirmationModal(false);
        }}
      >
        <Modal.Body>Are you sure you want to update the status?</Modal.Body>
        <Modal.Footer>
          <Flowbite theme={{ theme: customTheme }}>
            <Button color="primary" onClick={onAlbumStatusChange}>
              {isSaveButtonLoading ? (
                <svg
                  aria-hidden="true"
                  className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Yes"
              )}
            </Button>
          </Flowbite>
          <Button
            color="light"
            onClick={() => {
              setUpdatedAlbumStatusValue(albumData?.albumStatus);
              setIsOpenAlbumStatusUpdateConfirmationModal(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
