import React, { useState, useEffect } from "react";
import Table from "./Table";
import axios, { AxiosError } from "axios";
import {
  UpdatedProductDataContext,
  AllProductDataContext,
  DBDataContext,
  StoreFontTokenGet,
} from "./DataContext";
import PageLoading from "../../components/PageLoading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApiInstance from "../../libs/axios";

function ProductPage() {
  const [allShopifyProductsData, setAllShopifyProductsData] = useState([]);
  const [dbProductsData, setDbProductsData] = useState([]);
  const [updatedAllProductsData, setUpdatedAllProductsData] = useState([]);

  const [isSecondApiCallSucceed, setIsSecondApiCallSucceed] = useState(false);
  const [getAllShopifyProductsFlag, setGetAllShopifyProductsFlag] =
    useState(false);
  const [getDbProductsFlag, setGetDbProductsFlag] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForToken, setIsLoadingForToken] = useState(true);
  const [oneDataNotFoundFlag, setOneDataNotFoundFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [APP_STOREFRONTTOKEN, setAPP_STOREFRONTTOKEN] = useState(null);

  const updateAllProductsData = () => {
    // combine allProductsData with dbProductsData
    setIsLoading(true);
    const updatedProducts = allShopifyProductsData?.map((shopifyProduct) => {
      const dbProduct = dbProductsData?.find?.(
        (dbProduct) => dbProduct?.id === shopifyProduct?.id
      );
      if (dbProduct) {
        let mergeProducts = {
          ...shopifyProduct,
          ...dbProduct,
          variants: shopifyProduct?.variants?.map((variant) => {
            const matchingVariant = dbProduct.variants.find(
              (dbVariant) => dbVariant?.id === variant?.id
            );
            return matchingVariant
              ? { ...variant, ...matchingVariant }
              : variant;
          }),
        };
        setIsLoading(false);
        setGetDbProductsFlag(true);
        return mergeProducts;
      } else {
        setIsLoading(false);
        setGetDbProductsFlag(true);
        return shopifyProduct;
      }
    });
    setUpdatedAllProductsData(updatedProducts);
  };

  // API call for fetch StoreFontToken form MLVeda DB
  const getShopifyStoreFrontToken = async () => {
    setIsLoadingForToken(true);
    await axiosApiInstance
      .get("shop-secret/storefronttoken")
      .then((response) => {
        setAPP_STOREFRONTTOKEN(response?.data?.storeFrontToken);
        setIsLoadingForToken(false);
      })
      .catch((error) => {
        setErrorMsg(error);
        toast.error("Reload the page to get StoreFrontToken!", {
          position: "top-right",
        });
        setIsLoadingForToken(false);
      });
    return;
  };
  useEffect(() => {
    getShopifyStoreFrontToken();
  }, []);

  useEffect(() => {
    if (!APP_STOREFRONTTOKEN || errorMsg) {
      return;
    }
    setIsLoading(true);
    // get AllShopifyProdutData form shopify store-front
    const fetchShopifyAllProductsData = async () => {
      let response;
      try {
        response = await axios.post(
          `${process.env.REACT_APP_SHOP_URL}/api/2024-01/graphql.json`,
          {
            query: `query MyQuery {
              products(first: 100) {
                edges {
                  node {
                    id
                    title
                    variants(first: 100) {
                      edges {
                        node {
                          id
                          sku
                          title
                        }
                      }
                    }
                    createdAt
                    updatedAt
                  }
                }
              }
            }`,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Storefront-Access-Token": APP_STOREFRONTTOKEN,
            },
          }
        );
        // make product data formate as we want for db
        const formattedProducts = response?.data?.data?.products?.edges?.map(
          (edge) => {
            return {
              id: edge?.node?.id?.split("/").pop(),
              title: edge?.node?.title,
              sku: edge?.node?.sku,
              createdAt: edge?.node?.createdAt,
              updatedAt: edge?.node?.updatedAt,
              variants: edge?.node?.variants?.edges?.map((variantEdge) => ({
                id: variantEdge?.node?.id?.split("/").pop(),
                title: variantEdge?.node?.title,
                sku: variantEdge?.node?.sku,
              })),
            };
          }
        );
        setAllShopifyProductsData(formattedProducts);
        setIsLoading(false);
        if (response) {
          setGetAllShopifyProductsFlag(true);
        }
      } catch (error) {
        setIsLoading(true);
        // generate new StoreFrontToken
        console.log(response);
        if (error instanceof AxiosError && error.response?.status == 401) {
          try {
            const res = await axiosApiInstance
              .put("shop-secret/storefronttoken")
              .then((res) => {
                return res;
              });
            if (res.status == 200) {
              getShopifyStoreFrontToken();
              setIsLoading(false);
            }
          } catch (error) {
            setErrorMsg(error);
            toast.error("Reload the page to to generate StoreFrontToken!", {
              position: "top-right",
            });
            setIsLoading(false);
          }
        }
        // setErrorMsg(error); at set this things why show error ??????
        toast.error("Reload the page to get all products!", {
          position: "top-right",
        });
        setOneDataNotFoundFlag(true);
      }
    };

    if (APP_STOREFRONTTOKEN) {
      fetchShopifyAllProductsData();
    }
  }, [APP_STOREFRONTTOKEN]);

  useEffect(() => {
    if (!APP_STOREFRONTTOKEN || errorMsg) {
      return;
    }
    setIsLoading(true);
    // take all customize products data form mlveda db
    const fetchDBProductsData = async () => {
      if (!APP_STOREFRONTTOKEN || errorMsg) {
        return;
      }
      setIsLoading(true);
      try {
        const response = await axiosApiInstance
          .get("/products/all")
          .then((data) => {
            if (data?.data?.length > 0) {
              let dbFilterData = data?.data?.map((product) => {
                let filterVariants = {
                  ...product,
                  variants: product?.variants?.filter((productvariant) => {
                    return !(productvariant?.customize_status == "none");
                  }),
                };
                setIsLoading(false);

                return filterVariants;
              });
              let filterProducts = dbFilterData?.filter(
                (product) => product?.variants?.length != 0
              );
              setDbProductsData(filterProducts);
              setIsSecondApiCallSucceed(true);
              setIsLoading(false);
              setGetDbProductsFlag(true);
            } else {
              setDbProductsData([]);
              setIsSecondApiCallSucceed(true);
              setIsLoading(false);
            }
          });
        setIsLoading(false);
        return response;
      } catch (error) {
        toast.error("Reload the page to get Customize Products!", {
          position: "top-right",
        });
        setOneDataNotFoundFlag(true);
        setDbProductsData([]);
        setIsSecondApiCallSucceed(false);
      }
    };

    if (allShopifyProductsData.length > 0) {
      fetchDBProductsData();
    }
  }, [allShopifyProductsData]);

  useEffect(() => {
    if (!APP_STOREFRONTTOKEN || errorMsg) {
      return;
    }
    setIsLoading(true);
    if (
      APP_STOREFRONTTOKEN &&
      allShopifyProductsData.length > 0 &&
      isSecondApiCallSucceed
    ) {
      setIsLoading(true);
      setGetDbProductsFlag(false);
      updateAllProductsData();
    } else {
      setOneDataNotFoundFlag(true);
    }
  }, [dbProductsData]);
  if (errorMsg) {
    throw errorMsg;
  }
  return (
    <>
      {isLoadingForToken &&
      isLoading &&
      !getAllShopifyProductsFlag &&
      !getDbProductsFlag ? (
        <PageLoading />
      ) : (
        <>
          <UpdatedProductDataContext.Provider
            value={[updatedAllProductsData, setUpdatedAllProductsData]}
          >
            <AllProductDataContext.Provider value={allShopifyProductsData}>
              <DBDataContext.Provider value={dbProductsData}>
                <StoreFontTokenGet.Provider value={APP_STOREFRONTTOKEN}>
                  <Table
                    getAllShopifyProducts={getAllShopifyProductsFlag}
                    getDbProducts={getDbProductsFlag}
                    oneDataNotFoundFlag={oneDataNotFoundFlag}
                  />
                </StoreFontTokenGet.Provider>
              </DBDataContext.Provider>
            </AllProductDataContext.Provider>
          </UpdatedProductDataContext.Provider>
        </>
      )}
    </>
  );
}

export default ProductPage;
