import { useNavigate } from "react-router-dom";
import LineItemDetails from "./LineItemDetails";
import { useState } from "react";

export default function OrderDetailsData({ orderData }) {
  const navigate = useNavigate();
  const created_at = new Date(
    orderData.length > 0 ? orderData[0].createdAt : "",
  );
  const fullMonth = created_at.toLocaleString("default", { month: "long" });
  const date = created_at.getDate();
  const fullYear = created_at.getFullYear();
  const hours =
    created_at.getHours() > 12
      ? created_at.getHours() % 12
      : created_at.getHours();
  const minutes = created_at.getMinutes();
  const hourClock = created_at.getHours() > 12 ? "pm" : "am";
  const orderDate =
    fullMonth +
    " " +
    date +
    ", " +
    fullYear +
    " at " +
    hours +
    ":" +
    minutes +
    " " +
    hourClock;

  const onBackButtonClick = () => {
    navigate(-1);
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [, setPreviousIndex] = useState(currentIndex - 1);
  const [, setNextIndex] = useState(currentIndex + 1);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <div className="flex items-center justify-between pl-10 pr-24 mt-5 gap-6">
        <div className="self-baseline">
          <div className="flex gap-9 items-center">
            <button
              type="button"
              className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
              onClick={onBackButtonClick}
            >
              <svg
                className="w-5 h-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                />
              </svg>
            </button>
            <div className="text-2xl font-medium">
              {orderData.length > 0 && orderData[0].name}
            </div>
          </div>
          <div className="ml-24 font-light text-gray-600 pt-2 pb-2">
            {orderDate}
          </div>
          {orderData.length > 0 &&
            orderData[0]?.customerDetails?.first_name &&
            orderData[0]?.customerDetails?.last_name && (
              <div className="flex gap-12 ml-24">
                <div className="w-9">Name</div>
                <div className="font-light text-gray-600">
                  {orderData[0].customerDetails.first_name +
                    " " +
                    orderData[0].customerDetails.last_name}
                </div>
              </div>
            )}
          <div className="flex gap-12 ml-24">
            <div className="w-9">Email</div>
            <div className="font-light text-gray-600">
              {orderData.length > 0 && orderData[0].email}
            </div>
          </div>
        </div>
        <div className="relative overflow-x-auto h-[12.5rem]">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="px-2 py-3 border border-slate-300 text-center"
                >
                  No.
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 border border-slate-300 text-center"
                >
                  Product
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 border border-slate-300 text-center"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {orderData.map((oData, i) => {
                let status = oData.albumStatus;
                status =
                  status === "inproduction"
                    ? "In Production"
                    : status.charAt(0).toUpperCase() + status.slice(1);
                return (
                  <tr
                    key={i}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 cursor-pointer"
                    onClick={() => {
                      if (i === currentIndex) return;
                      setIsLoading(true);
                      setCurrentIndex(i);
                    }}
                  >
                    <td className="px-2 py-2 border border-slate-300 text-center">
                      {i + 1}
                    </td>
                    <td className="px-2 py-2 border border-slate-300">
                      {oData.productDetails.title} (SKU:
                      {" " + oData.sku})
                    </td>
                    <td className="px-2 py-2 border border-slate-300 text-center">
                      {status}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <LineItemDetails
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        setPreviousIndex={setPreviousIndex}
        setNextIndex={setNextIndex}
        lineItemsCount={orderData.length}
        albumId={orderData.length > 0 && orderData[currentIndex].albumId}
        order_id={orderData.length > 0 && orderData[currentIndex].id}
      />
    </>
  );
}
