function TableTitle({ allProductCount, customProductCount }) {
  return (
    <div className="flex items-center mt-9 mb-6">
      <div className="text-3xl font-bold text-black rounded">
        Product Customizer
      </div>
      &nbsp;
      <div className="flex text-xl ml-4 space-x-6  ">
        <div>
          <span className="text-gray-500">All Products:</span>
          &nbsp;
          <span>{allProductCount}</span>
        </div>

        <div>
          <span className="text-gray-500">Customizable Products:</span>
          &nbsp;
          <span>{customProductCount}</span>
        </div>
      </div>
    </div>
  );
}

export default TableTitle;
