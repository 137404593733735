import { useRef, useState } from "react";
import BrowseProductsModal from "./BrowseProductsModal";
import axiosApiInstance from "../../libs/axios";
import { toast } from "react-toastify";
import OrderSuccessModal from "./OrderSuccessModal";

export default function ManualOrder() {
  const [isBrowseProductsModalOpen, setIsBrowseProductsModalOpen] =
    useState(false);
  const [isOrderSuccessModalOpen, setIsOrderSuccessModalOpen] = useState(false);

  const [selectedLineItems, setSelectedLineItems] = useState([]);
  const [selectedSkuList, setSelectedSkuList] = useState([]);
  const [selectedVariantsList, setSelectedVariantsList] = useState([]);
  const [createdOrderId, setCreatedOrderId] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null)

  const onCreateOrderClick = () => {
    const email = document.querySelector("#email").value;
    if (!email || !/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      document
        .querySelector("#invalid-email-notification")
        .classList.remove("hidden");
      return;
    }
    const orderName = document.querySelector("#order-name").value;
    if (!orderName) {
      document
        .querySelector("#invalid-order-name-notification")
        .classList.remove("hidden");
      return;
    }
    if (selectedLineItems.length === 0) {
      document
        .querySelector("#zero-products-selected-validation")
        .classList.remove("hidden");
      return;
    }
    setIsLoading(true);
    axiosApiInstance
      .get("/customers", { params: { email: email } })
      .then((data) => {
        const customerId = data.data.id;
        createOrder(customerId, email, orderName);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          axiosApiInstance
            .post("/customers", { email: email })
            .then((data) => {
              const customerId = data.id;
              createOrder(customerId, email, orderName);
            })
            .catch((error) => console.error(error));
        } else {
          toast.error("Something went wrong. Please try again.", {
            position: "top-right",
          });
          console.error(error);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const createOrder = (customerId, email, orderName) => {
    axiosApiInstance
      .post("/orders", {
        customerId: customerId,
        email: email,
        name: orderName,
        lineItems: selectedLineItems,
      })
      .then((data) => {
        if (data.data.constructor === Array) {
          setCreatedOrderId(data.data[0].id);
          setIsOrderSuccessModalOpen(true);
          setSelectedLineItems([]);
          setSelectedSkuList([]);
          setSelectedVariantsList([]);
        } else {
          toast.error("Order ID is already in use. Please enter a unique ID", { position: "top-right" });
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.response?.data?.error||"Something went wrong. Please try again.", {
          position: "top-right",
        });
      });
  };

  return (
    <>
      <div className="m-auto mt-16 mb-16 block p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 w-1/2">
        <div className="space-y-6">
          <div className="mb-6">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-10"
              placeholder="yourname@company.com"
              onChange={() => {
                !document
                  .querySelector("#invalid-email-notification")
                  .classList.contains("hidden") &&
                  document
                    .querySelector("#invalid-email-notification")
                    .classList.add("hidden");
              }}
            />
            <p
              id="invalid-email-notification"
              className="hidden mt-2 text-sm text-red-600 dark:text-red-500"
            >
              Please enter valid Email ID.
            </p>
          </div>
          <div className="mb-6">
            <label
              htmlFor="order-name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Order Name
            </label>
            <input
              id="order-name"
              ref={inputRef}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-10"
              onChange={(event) => {
                const newValue = event.target.value.replaceAll(' ','');
                inputRef.current.value = newValue;
                !document
                  .querySelector("#invalid-order-name-notification")
                  .classList.contains("hidden") &&
                  document
                    .querySelector("#invalid-order-name-notification")
                    .classList.add("hidden");
              }}
            />
            <p
              id="invalid-order-name-notification"
              className="hidden mt-2 text-sm text-red-600 dark:text-red-500"
            >
              Please enter order name.
            </p>
          </div>
          <div className="mb-6">
            <label
              htmlFor="skus"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Product/SKUs
            </label>
            <div className="flex gap-4">
              <input
                id="skus"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-10"
                value={selectedSkuList.join(", ")}
                readOnly
              />
              <button
                type="button"
                className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 h-10"
                onClick={() => setIsBrowseProductsModalOpen(true)}
              >
                Browse
              </button>
            </div>
            <p
              id="zero-products-selected-validation"
              className="hidden mt-2 text-sm text-red-600 dark:text-red-500"
            >
              Please select SKUs to add.
            </p>
          </div>
        </div>
        <button
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mt-4"
          onClick={onCreateOrderClick}
          disabled={isLoading}
        >
          {isLoading ? (
            <svg
              aria-hidden="true"
              className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          ) : (
            "Create Order"
          )}
        </button>
      </div>
      <BrowseProductsModal
        isBrowseProductsModalOpen={isBrowseProductsModalOpen}
        setIsBrowseProductsModalOpen={setIsBrowseProductsModalOpen}
        selectedLineItems={selectedLineItems}
        setSelectedLineItems={setSelectedLineItems}
        selectedSkuList={selectedSkuList}
        setSelectedSkuList={setSelectedSkuList}
        selectedVariantsList={selectedVariantsList}
        setSelectedVariantsList={setSelectedVariantsList}
      />
      <OrderSuccessModal
        isOrderSuccessModalOpen={isOrderSuccessModalOpen}
        setIsOrderSuccessModalOpen={setIsOrderSuccessModalOpen}
        createdOrderId={createdOrderId}
      />
    </>
  );
}
