import { useEffect, useRef, useState } from "react";
import axiosApiInstance from "../../libs/axios";
import { toast } from "react-toastify";
import SongDetailsUpdateModal from "./SongDetailsUpdateModal";

export default function SongsList({
  albumData,
  setAlbumData,
  sideA,
  sideB,
  recordSides,
  audioTimings,
  isAlbumEditable,
}) {
  const [aSongsList, setASongsList] = useState([]);
  const [bSongsList, setBSongsList] = useState([]);
  const [aTotalLength, setATotalLength] = useState(0);
  const [bTotalLength, setBTotalLength] = useState(0);

  useEffect(() => {
    albumData?.customerId &&
      axiosApiInstance
        .get("files/?customerId=" + albumData.customerId)
        .then((data) => {
          const tempSideA = albumData.sideA;
          const tempSideB = albumData.sideB;
          data.data.data.forEach((songData) => {
            const idxA = sideA
              .map((song) => song.toString())
              .indexOf(songData.id.toString());
            if (idxA !== -1) {
              tempSideA[idxA] = songData;
            }
            const idxB = sideB
              .map((song) => song.toString())
              .indexOf(songData.id.toString());
            if (idxB !== -1) {
              tempSideB[idxB] = songData;
            }
          });
          setATotalLength(
            tempSideA.reduce((n, { length }) => n + parseFloat(length), 0)
          );
          setBTotalLength(
            tempSideB.reduce((n, { length }) => n + parseFloat(length), 0)
          );
          setASongsList(tempSideA);
          setBSongsList(tempSideB);
        })
        .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <div className="pl-12 pb-6 text-2xl font-normal">Track List</div>
      <div className="grid grid-cols-2 justify-between pl-12 pr-12 gap-28">
        <SongsListComp
          side={"A"}
          songsList={aSongsList}
          albumData={albumData}
          setAlbumData={setAlbumData}
          setASongsList={setASongsList}
          totalLength={aTotalLength}
          setATotalLength={setATotalLength}
          audioTimings={audioTimings}
          isAlbumEditable={isAlbumEditable}
        />
        {recordSides === 2 && (
          <SongsListComp
            side={"B"}
            songsList={bSongsList}
            albumData={albumData}
            setAlbumData={setAlbumData}
            setBSongsList={setBSongsList}
            totalLength={bTotalLength}
            setBTotalLength={setBTotalLength}
            audioTimings={audioTimings}
            isAlbumEditable={isAlbumEditable}
          />
        )}
      </div>
    </>
  );
}

const SongsListComp = ({
  side,
  songsList,
  albumData,
  setAlbumData,
  setASongsList,
  setBSongsList,
  totalLength,
  setATotalLength,
  setBTotalLength,
  audioTimings,
  isAlbumEditable,
}) => {
  const [isSongDetailsUpdateModalOpen, setIsSongDetailsUpdateModalOpen] =
    useState(false);
  const [songData, setSongData] = useState({});
  const [songName, setSongName] = useState(songData?.title);
  const [songArtist, setSongArtist] = useState(songData?.artist);
  const totalMinutes = parseInt(totalLength / 60);
  const totalSeconds = parseInt((totalLength / 60 - totalMinutes) * 60);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef();
  const [deletionLoading, setDeletionLoading] = useState(false);
  const maxNumberOfFiles = 15;
  const onDeleteSongClick = (songId) => {
    setDeletionLoading(true);
    const newSongsList = songsList
      .filter((song) => !(typeof song === "number" || typeof song === "string"))
      .filter((song) => song?.id.toString() !== songId.toString())
      .map((song) => song.id.toString());
    const sideA = side === "A" ? newSongsList : albumData.sideA;
    const sideB = side === "B" ? newSongsList : albumData.sideB;
    axiosApiInstance
      .put("/orders", {
        albumId: albumData.albumId,
        sideA: sideA,
        sideB: sideB,
        albumStatus: albumData.albumStatus,
      })
      .then((data) => {
        setAlbumData({ ...albumData }, { ...data.data });
        const tempASongsList = [];
        const tempBSongsList = [];
        data.data.sideA.forEach((aId) => {
          tempASongsList.push(
            songsList
              .filter(
                (song) =>
                  !(typeof song === "number" || typeof song === "string")
              )
              .filter((song) => song.id.toString() === aId.toString())[0]
          );
        });
        data.data.sideB.forEach((bId) => {
          tempBSongsList.push(
            songsList
              .filter(
                (song) =>
                  !(typeof song === "number" || typeof song === "string")
              )
              .filter((song) => song.id.toString() === bId.toString())[0]
          );
        });
        if (side === "A") {
          setATotalLength(
            tempASongsList.reduce((n, { length }) => n + parseFloat(length), 0)
          );
          setASongsList(tempASongsList);
        }
        if (side === "B") {
          setBTotalLength(
            tempBSongsList.reduce((n, { length }) => n + parseFloat(length), 0)
          );
          setBSongsList(tempBSongsList);
        }
        toast.success("Song Deleted Successfully!", {
          position: "top-right",
        });
      })
      .catch((error) => console.error(error))
      .finally(() => setDeletionLoading(false));
  };

  const onAddNewSong = async (e) => {
    setIsLoading(true);
    if (e.target.files.length === 0) {
      setIsLoading(false);
      return;
    }
    for (const file of e.target.files) {
      if (!file.type.includes("video") && !file.type.includes("audio")) {
        setIsLoading(false);
        toast.error(`File type is not supported`, {
          position: "top-right",
        });
        return;
      }
    }
    if (e.target.files.length > maxNumberOfFiles) {
      setIsLoading(false);
      toast.error(`You can upload only ${maxNumberOfFiles} Files at once.`, {
        position: "top-right",
      });
      return;
    }

    async function getAllFilesDuration(fileList) {
      function getBase64(file) {
        const reader = new FileReader();
        const audio = document.createElement("audio");
        return new Promise((resolve) => {
          reader.onload = (ev) => {
            audio.src = ev.target.result;
            audio.addEventListener("durationchange", () => {
              resolve(audio.duration);
            });
          };
          reader.readAsDataURL(file);
        });
      }

      const promises = [];
      for (let i = 0; i < fileList.length; i++) {
        promises.push(getBase64(fileList[i]));
      }
      return await Promise.all(promises);
    }

    const allFilesDuration = await getAllFilesDuration(e.target.files);
    const filesData = [];
    let count = 0;
    for (const file of e.target.files) {
      filesData.push({
        name: file.name,
        type: file.type,
        size: parseFloat(file.size / (1024 * 1024)),
        duration: allFilesDuration[count],
      });
      if (
        file.type.indexOf("audio") !== -1 ||
        file.type.indexOf("video") !== -1 ||
        parseFloat(file.size / (1024 * 1024)) <= 30
      ) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        formData.append("customerId", albumData.customerId);
        formData.append("length", allFilesDuration[count]);
        await axiosApiInstance
          .post("/files", formData)
          .then(async (data) => {
            const songId = data.data.id;
            const sideA = albumData.sideA;
            const sideB = albumData.sideB;
            if (side === "A") {
              sideA.push(songId);
              setASongsList((currSongsList) => [...currSongsList, data.data]);
            } else if (side === "B") {
              sideB.push(songId);
              setBSongsList((currSongsList) => [...currSongsList, data.data]);
            }
            await axiosApiInstance
              .put("/orders", {
                albumId: albumData.albumId,
                sideA: sideA,
                sideB: sideB,
                albumStatus: albumData.albumStatus,
              })
              .then((data) => {
                setAlbumData((currData) => ({ ...currData, ...data.data }));
                toast.success("Song Added Successfully!", {
                  position: "top-right",
                });
              })
              .catch((error) => console.error(error));
          })
          .catch((error) => {
            console.error(error);
            toast.error("Something went wrong. Please try again!", {
              position: "top-right",
            });
          });
      }
      count += 1;
    }
    fileInputRef.current.value = "";
    setIsLoading(false);
    if (side === "A") {
      setATotalLength(
        (currTotalLength) =>
          currTotalLength + allFilesDuration.reduce((n, length) => n + length)
      );
    }
    if (side === "B") {
      setBTotalLength(
        (currTotalLength) =>
          currTotalLength + allFilesDuration.reduce((n, length) => n + length)
      );
    }
  };
  const onUpdateSongDetailsClick = (e) => {
    const songId =
      e.target.nodeName === "path"
        ? e.target.parentElement.parentElement.parentElement.parentElement.parentElement.getAttribute(
            "data-song-id"
          )
        : e.target.nodeName === "svg"
          ? e.target.parentElement.parentElement.parentElement.parentElement.getAttribute(
              "data-song-id"
            )
          : e.target.parentElement.parentElement.parentElement.getAttribute(
              "data-song-id"
            );
    const song = songsList.filter(
      (songData) => songData?.id?.toString?.() === songId?.toString?.()
    )[0];
    setSongData(song);
    setSongName(song?.title);
    setSongArtist(song?.artist);
    setIsSongDetailsUpdateModalOpen(true);
  };

  const draggedItemRef = useRef(null);

  const onDragStart = (e, index) => {
    draggedItemRef.current = songsList[index];
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  const onDragOver = (index) => {
    if(!isAlbumEditable) return;
    if (draggedItemRef.current === songsList[index]) return;

    const newItems = [...songsList];
    const draggedItemIndex = newItems.indexOf(draggedItemRef.current);

    // Remove dragged item from its current position
    newItems.splice(draggedItemIndex, 1);

    // Insert dragged item at the new position
    newItems.splice(index, 0, draggedItemRef.current);

    if (side === "A") {
      setASongsList(newItems);
    }
    if (side === "B") {
      setBSongsList(newItems);
    }
  };

  const onDragEnd = () => {
    draggedItemRef.current = null;
    if (albumData?.albumId && songsList.length > 0) {
      let sideAList = albumData.sideA;
      let sideBList = albumData.sideB;
      if (side === "A") {
        sideAList = songsList.map((songData) => songData.id);
      }
      if (side === "B") {
        sideBList = songsList.map((songData) => songData.id);
      }
      axiosApiInstance
        .put("/orders", {
          albumId: albumData.albumId,
          sideA: sideAList,
          sideB: sideBList,
        })
        .then((data) => {
          setAlbumData({ ...albumData }, { ...data.data });
          if (side === "A") {
            setASongsList(songsList);
          }
          if (side === "B") {
            setBSongsList(songsList);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Something went wrong. Please refresh the page once.", {
            position: "top-right",
          });
        });
    }
  };

  useEffect(() => {
    if (side === "A") {
      setASongsList(songsList);
    }
    if (side === "B") {
      setBSongsList(songsList);
    }

    const handleDragEnd = () => onDragEnd();
    window.addEventListener("dragend", handleDragEnd);

    return () => window.removeEventListener("dragend", handleDragEnd);
  }, []);
  return (
    <div>
      <div className="flex justify-between gap-12 mb-4">
        <div>
          <div>Side {side}</div>
          <div className="font-extralight">
            {
              songsList.filter(
                (song) =>
                  !(typeof song === "number" || typeof song === "string")
              ).length
            }{" "}
            {songsList.length !== 1 ? "songs" : "song"}, {totalMinutes} Minutes,{" "}
            {totalSeconds} Seconds. (Max limit: {audioTimings} minutes)
          </div>
        </div>
        <input
          type="file"
          multiple
          accept="audio/*, video/*"
          className="hidden"
          ref={fileInputRef}
          onChange={onAddNewSong}
        />
        <button
          type="button"
          className={`text-white bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ${isAlbumEditable ? " hover:bg-blue-800  cursor-pointer" : "cursor-not-allowed"}`}
          onClick={(e) => e.target.previousElementSibling.click()}
          disabled={isLoading || !isAlbumEditable}
        >
          {isLoading ? (
            <svg
              aria-hidden="true"
              className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          ) : (
            "+ Add"
          )}
        </button>
      </div>
      {songsList.length > 0 && (
        <div className="relative overflow-x-auto">
          <ul>
            {songsList
              .filter(
                (song) =>
                  !(typeof song === "number" || typeof song === "string")
              )
              .map((song, idx) => {
                const songTime = parseFloat(song.length / 60);
                const minutes = parseInt(songTime);
                const seconds = parseInt((songTime - minutes) * 60);
                return (
                  <li
                    key={`${side}-${song.id}`}
                    data-song-id={song.id}
                    className="flex justify-between gap-4"
                    onDragOver={() => onDragOver(idx)}
                  >
                    <div className="flex items-center gap-4">
                      <span
                        className="cursor-grab pb-2 pt-2"
                        draggable
                        onDragStart={(e) => onDragStart(e, idx)}
                        onDragEnd={onDragEnd}
                      >
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            d="M5 7h14M5 12h14M5 17h14"
                          />
                        </svg>
                      </span>
                      <span className="w-auto text-left pb-2 pt-2">
                        <span className="font-normal text-black text-base">
                          {song.title}
                        </span>
                        <br />
                        <span className="font-extralight">{song.artist}</span>
                      </span>
                    </div>
                    <div className="flex items-center gap-3">
                      <span className="pb-2 pt-2">
                        {songTime ? minutes + ":" + seconds : ""}
                      </span>
                      {deletionLoading ? (
                        <svg
                          aria-hidden="true"
                          className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      ) : (
                        <span className="pb-2 pt-2">
                          <button
                            onClick={() => onDeleteSongClick(song.id)}
                            disabled={deletionLoading || !isAlbumEditable}
                            className={`${isAlbumEditable ? "cursor-pointer" : "cursor-not-allowed"}`}
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                              />
                            </svg>
                          </button>
                        </span>
                      )}
                      <span className="pb-2 pt-2">
                        <button
                          onClick={onUpdateSongDetailsClick}
                          disabled={deletionLoading || !isAlbumEditable}
                          className={`${isAlbumEditable ? "cursor-pointer" : "cursor-not-allowed"}`}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                            />
                          </svg>
                        </button>
                      </span>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
      <SongDetailsUpdateModal
        isSongDetailsUpdateModalOpen={isSongDetailsUpdateModalOpen}
        setIsSongDetailsUpdateModalOpen={setIsSongDetailsUpdateModalOpen}
        songId={songData?.id}
        songName={songName}
        setSongName={setSongName}
        songArtist={songArtist}
        setSongArtist={setSongArtist}
        songsList={songsList}
        side={side}
        setASongsList={setASongsList}
        setBSongsList={setBSongsList}
      />
    </div>
  );
};
