import { useCallback, useEffect, useState } from "react";
import axiosApiInstance from "../../libs/axios";
import ImageViewer from "react-simple-image-viewer";
import { toast } from "react-toastify";

export default function CoverImages({ albumData, isAlbumEditable }) {
  const [imageAIndex, setImageAIndex] = useState(0);
  const [imageBIndex, setImageBIndex] = useState(0);
  const [isImageAModalOpen, setIsImageAModalOpen] = useState(false);
  const [isImageBModalOpen, setIsImageBModalOpen] = useState(false);

  const [isLoadingA, setIsLoadingA] = useState(false);
  const [isLoadingB, setIsLoadingB] = useState(false);

  const [aImages, setAImages] = useState([]);
  const [bImages, setBImages] = useState([]);

  const openAImageViewer = useCallback((index) => {
    setImageAIndex(index);
    setIsImageAModalOpen(true);
  }, []);

  const openBImageViewer = useCallback((index) => {
    setImageBIndex(index);
    setIsImageBModalOpen(true);
  }, []);

  const closeAImageViewer = () => {
    setImageAIndex(0);
    setIsImageAModalOpen(false);
  };

  const closeBImageViewer = () => {
    setImageBIndex(0);
    setIsImageBModalOpen(false);
  };

  const onSideAImageChange = (e) => {
    if (e.target.files.length > 0) {
      if (!e.target.files[0].type.startsWith("image")) {
        e.target.value = "";
        document.querySelector("#side-a-label-action").classList.add("hidden");
        toast.error("You can upload only images.", { position: "top-right" });
        return;
      }
      const imageSizeMb = parseFloat(e.target.files[0].size / (1024 * 1024));
      if (imageSizeMb > 30) {
        toast.error(
          "File size should not exceed 30 MB. Please upload another file.",
          { position: "top-right" }
        );
        return;
      }
      setIsLoadingA(true);
      setAImages([URL.createObjectURL(e.target.files[0])]);
      const formData = new FormData();
      const file = e.target.files[0];
      formData.append("file", file);
      formData.append("customerId", albumData.customerId);
      axiosApiInstance
        .post("/files", formData)
        .then((data) => {
          axiosApiInstance
            .put("/orders", {
              albumId: albumData.albumId,
              frontLabel: data.data.id,
            })
            .then(() => {
              document
                .querySelector("#side-a-label-action")
                .classList.remove("hidden");
              document
                .querySelector("#side-a-label-action")
                .classList.add("flex");
              const img = new Image();
              img.onload = () => {
                if (img.width < 1000 || img.height < 1000) {
                  toast.info(
                    "The image is uploaded successfully! However the resolution of the image is not very good and it will not print clearly. You can choose to replace the file or we can use the currently uploaded one.",
                    { position: "top-right" }
                  );
                } else {
                  toast.success("The image is uploaded successfully!");
                }
              };
              img.src = URL.createObjectURL(e.target.files[0]);
            })
            .catch((error) => {
              console.error(error);
              toast.error("Something went wrong. Please try again.", {
                position: "top-right",
              });
            });
        })
        .catch((error) => {
          console.error(error);
          toast.error("Something went wrong. Please try again.", {
            position: "top-right",
          });
        })
        .finally(() => setIsLoadingA(false));
    }
  };
  const onSideBImageChange = (e) => {
    if (e.target.files.length > 0) {
      if (!e.target.files[0].type.startsWith("image")) {
        e.target.value = "";
        document.querySelector("#side-b-label-action").classList.add("hidden");
        toast.error("You can upload only images.", { position: "top-right" });
        return;
      }
      const imageSizeMb = parseFloat(e.target.files[0].size / (1024 * 1024));
      if (imageSizeMb > 30) {
        toast.error(
          "File size should not exceed 30 MB. Please upload another file.",
          { position: "top-right" }
        );
        return;
      }
      setIsLoadingB(true);
      setBImages([URL.createObjectURL(e.target.files[0])]);
      const formData = new FormData();
      const file = e.target.files[0];
      formData.append("file", file);
      formData.append("customerId", albumData.customerId);
      axiosApiInstance
        .post("/files", formData)
        .then((data) => {
          axiosApiInstance
            .put("/orders", {
              albumId: albumData.albumId,
              backLabel: data.data.id,
            })
            .then(() => {
              document
                .querySelector("#side-b-label-action")
                .classList.remove("hidden");
              document
                .querySelector("#side-b-label-action")
                .classList.add("flex");
              const img = new Image();
              img.onload = () => {
                if (img.width < 1000 || img.height < 1000) {
                  toast.info(
                    "The image is uploaded successfully! However the resolution of the image is not very good and it will not print clearly. You can choose to replace the file or we can use the currently uploaded one.",
                    { position: "top-right" }
                  );
                } else {
                  toast.success("The image is uploaded successfully!", {
                    position: "top-right",
                  });
                }
              };
              img.src = URL.createObjectURL(e.target.files[0]);
            })
            .catch((error) => {
              console.error(error);
              toast.error("Something went wrong. Please try again.", {
                position: "top-right",
              });
            });
        })
        .catch((error) => {
          console.error(error);
          toast.error("Something went wrong. Please try again.", {
            position: "top-right",
          });
        })
        .finally(() => setIsLoadingB(false));
    }
  };

  const onSideAImageDelete = () => {
    setIsLoadingA(true);
    const frontLabel = albumData?.frontLabel;
    axiosApiInstance
      .delete(`/files/?id=${frontLabel}`)
      .then(() => {
        document.querySelector("#side-a-label-action").classList.add("hidden");
        document.querySelector("#side-a-label-input").value = "";
        toast.success("The image is deleted successfully!", {
          position: "top-right",
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
        });
      })
      .finally(() => setIsLoadingA(false));
  };

  const onSideBImageDelete = () => {
    setIsLoadingB(true);
    const backLabel = albumData?.backLabel;
    axiosApiInstance
      .delete(`/files/?id=${backLabel}`)
      .then(() => {
        document.querySelector("#side-b-label-action").classList.add("hidden");
        document.querySelector("#side-b-label-input").value = "";
        toast.success("The image is deleted successfully!", {
          position: "top-right",
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
        });
      })
      .finally(() => setIsLoadingB(false));
  };

  useEffect(() => {
    albumData?.customerId &&
      axiosApiInstance
        .get("files/?customerId=" + albumData.customerId)
        .then((data) => {
          data.data.data.forEach((fileData) => {
            if (
              fileData?.id?.toString?.() === albumData?.frontLabel?.toString?.()
            ) {
              setAImages([fileData.URL]);
              fetch(fileData.URL)
                .then((r) => r.blob())
                .then((data) => {
                  const file = new File([data], fileData.title, {
                    type: data.type,
                  });
                  const list = new DataTransfer();
                  list.items.add(file);
                  document.querySelector("#side-a-label-input").files =
                    list.files;
                  document
                    .querySelector("#side-a-label-action")
                    .classList.remove("hidden");
                  document
                    .querySelector("#side-a-label-action")
                    .classList.add("flex");
                })
                .catch((err) => console.error(err));
            }
            if (
              fileData?.id?.toString?.() === albumData?.backLabel?.toString?.()
            ) {
              setBImages([fileData.URL]);
              fetch(fileData.URL)
                .then((r) => r.blob())
                .then((data) => {
                  const file = new File([data], fileData.title, {
                    type: data.type,
                  });
                  const list = new DataTransfer();
                  list.items.add(file);
                  document.querySelector("#side-b-label-input").files =
                    list.files;
                  document
                    .querySelector("#side-b-label-action")
                    .classList.remove("hidden");
                  document
                    .querySelector("#side-b-label-action")
                    .classList.add("flex");
                })
                .catch((err) => console.error(err));
            }
          });
        })
        .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <div className="pl-12 pb-6 text-2xl font-normal">Custom Labels</div>
      <div className="grid grid-cols-2 justify-between pl-12 pr-12 gap-28">
        <div>
          <label
            className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
            htmlFor="side-a-label-input"
          >
            Front Label
          </label>
          <div className="flex gap-4">
            <input
              className={`block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${isAlbumEditable ? "cursor-pointer" : "cursor-not-allowed"}`}
              id="side-a-label-input"
              type="file"
              disabled={!isAlbumEditable}
              accept="image/jpeg, image/png"
              onChange={onSideAImageChange}
            />
            {isLoadingA && (
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            )}
          </div>
          <div className="flex justify-between items-center gap-12 pt-1">
            <div className="text-sm font-light">
              JPG, JPEG, and PNG (MIN 1000x1000px)
            </div>
            <div id="side-a-label-action" className="hidden">
              <button
                className="border-r-2 pr-1 text-blue-600"
                onClick={() => setIsImageAModalOpen(true)}
              >
                view
              </button>
              <button
                className={`border-r-2 pl-1 pr-1 text-blue-600 ${isAlbumEditable ? "cursor-pointer" : "cursor-not-allowed"}`}
                onClick={() =>
                  document.querySelector("#side-a-label-input").click()
                }
                disabled={!isAlbumEditable}
              >
                replace
              </button>
              <button
                className={`pl-1 text-red-600 ${isAlbumEditable ? "cursor-pointer" : "cursor-not-allowed"}`}
                onClick={onSideAImageDelete}
                disabled={!isAlbumEditable}
              >
                delete
              </button>
            </div>
          </div>
        </div>
        <div>
          <label
            className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
            htmlFor="side-b-label-input"
          >
            Back Label
          </label>
          <div className="flex gap-4">
            <input
              className={`block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${isAlbumEditable ? "cursor-pointer" : "cursor-not-allowed"} `}
              id="side-b-label-input"
              type="file"
              disabled={!isAlbumEditable}
              accept="image/jpeg, image/png"
              onChange={onSideBImageChange}
            />
            {isLoadingB && (
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            )}
          </div>
          <div className="flex justify-between items-center gap-12 pt-1">
            <div className="text-sm font-light">
              JPG, JPEG, and PNG (MIN 1000x1000px)
            </div>
            <div id="side-b-label-action" className="hidden">
              <button
                className="border-r-2 pr-1 text-blue-600"
                onClick={() => setIsImageBModalOpen(true)}
              >
                view
              </button>
              <button
                className={`border-r-2 pl-1 pr-1 text-blue-600 ${isAlbumEditable ? "cursor-pointer" : "cursor-not-allowed"}`}
                onClick={() =>
                  document.querySelector("#side-b-label-input").click()
                }
                disabled={!isAlbumEditable}
              >
                replace
              </button>
              <button
                className={`pl-1 text-red-600 ${isAlbumEditable ? "cursor-pointer" : "cursor-not-allowed"}`}
                onClick={onSideBImageDelete}
                disabled={!isAlbumEditable}
              >
                delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
      <div>
        {aImages.map((src, index) => (
          <img
            src={src}
            onClick={() => openAImageViewer(index)}
            width="300"
            key={index}
            style={{ margin: "2px" }}
            alt=""
            className="hidden"
          />
        ))}
        {isImageAModalOpen && (
          <ImageViewer
            src={aImages}
            currentIndex={imageAIndex}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeAImageViewer}
            closeComponent={
              <svg
                className="w-6 h-6 text-white dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  fill="white"
                  d="M6 18 17.94 6M18 18 6.06 6"
                />
              </svg>
            }
          />
        )}
      </div>
      <div>
        {bImages.map((src, index) => (
          <img
            src={src}
            onClick={() => openBImageViewer(index)}
            width="300"
            key={index}
            style={{ margin: "2px" }}
            alt=""
            className="hidden"
          />
        ))}
        {isImageBModalOpen && (
          <ImageViewer
            src={bImages}
            currentIndex={imageBIndex}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeBImageViewer}
          />
        )}
      </div>
    </>
  );
}
